import { Button, Card, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const Info = () => {
  const history = useHistory();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onButtonClicked = () => {
    if (isChecked) {
      localStorage.setItem('dontShow', '1');
    }

    history.push('/main');
  };

  useEffect(() => {
    const dontShow = localStorage.getItem('dontShow');
    if (dontShow === '1') {
      history.push('/main');
    }
  }, [history]);

  return (
    <div className="info">
      <Card className="info__card">
        <h2 className="info__title">INVIO DI COMMUNICAZIONE UFFICIALE</h2>
        <p className="info__paragraph">
          PER INVIARE UNA COMUNICAZIONE UFFICIALE SEGUI QUETI PASSI (NOTA:{' '}
          <span className="normal-case">
            caricando un documento significa che tu dichiari che il documento e
            stato autorizzato di essere mandato):
          </span>
        </p>
        <ol className="info__list">
          <li>CARICARE IL DOCUMENTO FIRMATO IN FORMATO PDF</li>
          <li>SCEGLI A CHI DEVE ANDARE LA COMMUNICAZIONE</li>
          <li>CONFERMA CHE IL DOCUMENTO E AUTORIZZATO</li>
          <li>INVIA IL DOCUMENTO UNA BOZZA DEL'INVIO SARA CREATA</li>
          <li>CONFERMA L'INVIO</li>
          <li>PUOI VEDERE I RESULTATI DEL'INVIO SUL MENU "INVII"</li>
        </ol>

        <Checkbox
          className="info__checkbox"
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        >
          NON FARMI PIU VEDERE QUESTA PAGINA
        </Checkbox>
        <Button
          className="info__button"
          type="primary"
          onClick={onButtonClicked}
        >
          avanza a fare l'invio
        </Button>
      </Card>
    </div>
  );
};

export default Info;
