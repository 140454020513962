import React, { useState } from 'react';
import {
  HomeOutlined,
  InboxOutlined,
  LogoutOutlined,
  MenuOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

import { useTypedDispatch, useTypedSelector } from 'store';
import { logOut } from 'store/user/thunkActions';

import image from 'assets/img/main-logo.png';

const Header = () => {
  const dispatch = useTypedDispatch();

  const { isAuth } = useTypedSelector((state) => state.user);

  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);

  const onLogout = () => {
    dispatch(logOut());
    setIsDrawerOpened(false);
  };

  return (
    <div className="header">
      <div className="header__main">
        <div className="header__icon">
          <img src={image} alt="logo" />
        </div>
        <h1 className="header__title">Portobello di Gallura</h1>
      </div>

      {isAuth && (
        <MenuOutlined
          className="header__menu-button"
          onClick={() => setIsDrawerOpened(true)}
        />
      )}

      <Drawer
        title="Menu"
        placement="right"
        width={270}
        visible={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
      >
        <div className="drawer__links" onClick={() => setIsDrawerOpened(false)}>
          <Link to="/home">
            <HomeOutlined />
            HOME
          </Link>
          <Link to="/main">
            <SendOutlined />
            SEND DOCUMENT
          </Link>
          <Link to="/home">
            <InboxOutlined />
            CONDO INBOX
          </Link>
        </div>

        <LogoutOutlined
          title="Disconnettersi"
          className="drawer__logout"
          onClick={onLogout}
        />
      </Drawer>
    </div>
  );
};

export default Header;
