import React, { useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { useTypedDispatch } from 'store';

import UploadDocument from 'components/UploadDocument';

import { setCurrentStep, setFormData, setGroups } from 'store/core';

import { FormInstance } from 'antd/lib/form';
import { IGroup, IUpload } from 'types';
import { apiClient } from 'utils';

const FirstStep = () => {
  const dispatch = useTypedDispatch();

  const onNext = async (values: {
    title: string;
    description: string;
    upload: IUpload;
  }) => {
    try {
      const data = {
        title: values.title,
        description: values.description,
        uploadId: values.upload.id,
        upload: values.upload,
      };

      dispatch(setFormData(data));
      dispatch(setCurrentStep(1));
    } catch (e) {
      console.error('onnext error', e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const groups: IGroup[] = await apiClient
        .service('groups')
        .find({ query: { $limit: -1 } });

      dispatch(setGroups(groups));
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <p>
        1. CARICARE IL DOCUMENTO FIRMATO IN FORMATO PDF (NOTA: Caricando un
        documento significa che tu dichiari che il documento e stato autorizzato
        di essere mandato)
      </p>

      <Form onFinish={onNext} layout="vertical">
        <Form.Item
          name="upload"
          rules={[{ required: true, message: 'Seleziona un documento!' }]}
        >
          <UploadDocument />
        </Form.Item>

        <Form.Item
          label="TITOLO DEL INVIO"
          name="title"
          rules={[
            { required: true, message: 'Si prega di inserire il titolo!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="TESTO DEL MESSAGIO CON L'INVIO:"
          name="description"
          rules={[
            { required: true, message: 'Si prega di inserire un messaggio!' },
          ]}
        >
          <Input.TextArea placeholder="Cari condomini. Vi mandiamo la convocazione della prossima assemblea..." />
        </Form.Item>

        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {(instance: FormInstance) => (
            <Button
              type="primary"
              htmlType="submit"
              className="next-button"
              disabled={
                !instance.getFieldValue('title') ||
                !instance.getFieldValue('description') ||
                !!instance
                  .getFieldsError()
                  .filter(({ errors }) => errors.length).length ||
                !instance.getFieldValue('upload')?.id
              }
            >
              scegli i destinatari
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default FirstStep;
