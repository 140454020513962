import { Paginated } from '@feathersjs/feathers';
import { Button, Card } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTypedDispatch } from 'store';

import { resetFormData, setCurrentStep } from 'store/core';
import { IDocument } from 'types';
import { apiClient } from 'utils';

const Home = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const [documents, setDocuments] = useState<IDocument[]>([]);

  const onCreateNew = () => {
    dispatch(setCurrentStep(0));
    dispatch(resetFormData());
    history.push('/main');
  };

  useEffect(() => {
    const fetchData = async () => {
      const query = {
        $limit: 50,
        $sort: { createdAt: -1 },
      };
      const res: Paginated<IDocument> = await apiClient
        .service('documents')
        .find({ query });

      setDocuments(res.data);
    };

    fetchData();
  }, []);

  return (
    <div className="home">
      <Card className="home__card">
        <h3>TUTTI GLI INVII</h3>

        <small>INVII 1 A {documents.length}</small>

        <div className="sixth__list">
          {documents.map((doc) => (
            <div key={doc.id} className="sixth__list-item">
              {moment(doc.createdAt).format('DD MMM YYYY')} - {doc.title}
            </div>
          ))}
        </div>

        <Button
          style={{ display: 'block' }}
          type="primary"
          onClick={() => onCreateNew()}
        >
          crea un messagio al condominio
        </Button>
      </Card>
    </div>
  );
};

export default Home;
