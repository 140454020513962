import { combineReducers } from 'redux';

import user, { StoreUser } from 'store/user';
import core, { StoreCore } from 'store/core';

export interface RootState {
  user: StoreUser;
  core: StoreCore;
}

export default combineReducers({
  user,
  core,
});
