import React from 'react';
import { Button, Card, Steps } from 'antd';
import { useHistory } from 'react-router';
import { useTypedDispatch, useTypedSelector } from 'store';

import CreateFocument from './CreateFocument';
import ChooseGroups from './ChooseGroups';
import ConfirmDocument from './ConfirmDocument';
import ConfirmDraft from './ConfirmDraft';
import WholeForm from './WholeForm';
import AllMailings from './AllMailings';

import { resetFormData, setCurrentStep } from 'store/core';
import { ArrowLeftOutlined } from '@ant-design/icons';

const Main = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const currentStep = useTypedSelector((state) => state.core.currentStep);

  const onGoBackTheStep = () => dispatch(setCurrentStep(currentStep - 1));

  const onCancel = () => {
    history.push('/home');
    dispatch(resetFormData());
    dispatch(setCurrentStep(0));
  };

  const pages = [
    <CreateFocument />,
    <ChooseGroups />,
    <ConfirmDocument />,
    <ConfirmDraft />,
    <WholeForm />,
    <AllMailings />,
  ];

  return (
    <div className="main">
      <Card className="main__card">
        <h2 className="main__title">INVIO DI COMMUNICAZIONE UFFICIALE</h2>
        <Steps className="main__steps" current={currentStep}>
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
        </Steps>

        {currentStep > 0 && (
          <Button
            className="main__goback"
            type="link"
            onClick={() => onGoBackTheStep()}
          >
            <ArrowLeftOutlined /> Tornare
          </Button>
        )}
        <div className="main__body">{pages[currentStep]}</div>

        {currentStep !== 5 && (
          <Button
            className="main__cancel-button"
            type="text"
            onClick={onCancel}
          >
            <ArrowLeftOutlined /> Cancellare
          </Button>
        )}
      </Card>
    </div>
  );
};

export default Main;
