import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTypedDispatch } from 'store';
import moment from 'moment';

import { resetFormData, setCurrentStep } from 'store/core';
import { apiClient } from 'utils';

import { Paginated } from '@feathersjs/feathers';
import { IDocument } from 'types';

const Sixth = () => {
  const dispatch = useTypedDispatch();

  const [documents, setDocuments] = useState<IDocument[]>([]);

  const onCreateNew = () => {
    dispatch(resetFormData());
    dispatch(setCurrentStep(0));
  };

  useEffect(() => {
    const query = {
      $limit: 50,
      $sort: { createdAt: -1 },
    };
    const fetchData = async () => {
      const res: Paginated<IDocument> = await apiClient
        .service('documents')
        .find({ query });

      setDocuments(res.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <h3>TUTTI GLI INVII</h3>

      <small>INVII 1 A {documents.length}</small>

      <div className="sixth__list">
        {documents.map((doc) => (
          <div key={doc.id} className="sixth__list-item">
            {moment(doc.createdAt).format('DD MMM YYYY')} - {doc.title}
          </div>
        ))}
      </div>

      <Button className="next-button" type="primary" onClick={onCreateNew}>
        crea un nuovo invio
      </Button>
    </div>
  );
};

export default Sixth;
