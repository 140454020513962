import React from 'react'

const NotFound = () => {
	return (
		<div className="notfound">
			<h2>404</h2>
			<p>not found</p>
		</div>
	)
}

export default NotFound
