import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Form, Input } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';

import { logIn } from 'store/user/thunkActions';

const Login = () => {
  const history = useHistory();
  const dispatch = useTypedDispatch();

  const isAuth = useTypedSelector((state) => state.user.isAuth);

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      await dispatch(logIn(values));
    } catch (e) {
      console.error('Err while loggging', e);
    }
  };

  useEffect(() => {
    if (isAuth) {
      history.push('/info');
    }
  }, [isAuth, history]);

  return (
    <div className="login">
      <Card className="login__wrapper">
        <h2 className="login__title">Login</h2>

        <Form onFinish={onSubmit} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Button type="primary" htmlType="submit" className="login__submit">
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
