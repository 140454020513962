import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import { useTypedSelector } from 'store';

import Header from 'components/Header';
import Main from 'pages/Main';
import Home from 'pages/Home';
import Info from 'pages/Info';
import Login from 'pages/Login';
import NotFound from 'pages/404';

const Routes = () => (
  <Router>
    <Header />

    <Switch>
      <Redirect exact path="/" to="/login" />

      <Route exact path="/404" component={NotFound} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/main" component={wrapToLayout(Main)} />
      <Route exact path="/home" component={wrapToLayout(Home)} />
      <Route exact path="/info" component={wrapToLayout(Info)} />

      <Redirect to="/404" />
    </Switch>
  </Router>
);

const wrapToLayout = (Component: () => JSX.Element) => {
  return () => {
    const isAuth = useTypedSelector((state) => state.user.isAuth);

    return isAuth ? (
      <div className="layout">
        <Component />
      </div>
    ) : (
      <Redirect to="/login" />
    );
  };
};

export default Routes;
