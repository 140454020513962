import React from 'react';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Upload, Row, Button } from 'antd';
import axios from 'axios';
import { useTypedSelector } from 'store';

import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { IUpload } from 'types';

type Props = {
  value?: IUpload;
  onChange?: (val: IUpload) => void;
  type?: 'add' | 'edit';
};

const UploadDocument: React.FC<Props> = ({ value, onChange, type = 'add' }) => {
  const token = useTypedSelector((state) => state.user.token);

  const onDocumentUpload = async (options: RcCustomRequestOptions) => {
    const { file } = options;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_FILE_URL}`,
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
      onChange(data[0]);
    } catch (e) {
      console.error('upload error', e);
    }
  };

  return (
    <Upload
      className="upload-document"
      fileList={[]}
      accept=".pdf"
      customRequest={onDocumentUpload}
    >
      <Row align="middle" wrap={type === 'edit' ? false : true}>
        {type === 'add' && (
          <Button icon={<DownloadOutlined />}>cerca un documento</Button>
        )}
        <span className="upload-document__name">
          {value?.originalname?.length > 40
            ? value?.originalname?.slice(0, 39) + '...'
            : value?.originalname}
        </span>
        {type === 'edit' && <EditOutlined className="edit-icon" />}
      </Row>
    </Upload>
  );
};

export default UploadDocument;
