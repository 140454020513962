import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';

import { setCurrentStep } from 'store/core';

const Third = () => {
  const dispatch = useTypedDispatch();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const formData = useTypedSelector((state) => state.core.formData);

  const onNext = () => {
    dispatch(setCurrentStep(3));
  };

  return (
    <div>
      <p>TITOLO DEL INVIO: "{formData.title}"</p>
      <p>
        3. CONFERMA CHE IL DOCUMENTO E AUTORIZATO
        <br />
        (NOTA: Caricando un documento significa che tu dichiari che il documento
        e stato autorizzato di essere mandato)
      </p>

      <p>DOCUMENTI AUTORIZATI</p>
      <Checkbox
        className="info__checkbox"
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        - {formData.upload?.originalname}
      </Checkbox>

      <Button
        type="primary"
        className="next-button"
        disabled={!isChecked}
        onClick={onNext}
      >
        avanza a inviare il documento
      </Button>
    </div>
  );
};

export default Third;
