import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Provider } from 'react-redux';

import Routes from 'routes';

import store from 'store';
import { checkIsUserAuth } from 'store/user/thunkActions';

import 'antd/dist/antd.css';
import 'assets/styles.scss';

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkUser = async () => {
    try {
      await store.dispatch(checkIsUserAuth());
    } catch (e) {
      console.error('Check is user auth failed because idi naher', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return isLoading ? (
    <div className="page-loading">
      <ReactLoading type="bars" color="#69C262" />
    </div>
  ) : (
    <Provider store={store}>
      <div className="App">
        <Routes />
      </div>
    </Provider>
  );
}

export default App;
