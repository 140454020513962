import React from 'react';
import { Button } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';

import { setCurrentStep } from 'store/core';

const Third = () => {
  const dispatch = useTypedDispatch();

  const formData = useTypedSelector((state) => state.core.formData);

  const onNext = () => {
    dispatch(setCurrentStep(4));
  };

  return (
    <div>
      <p>TITOLO DEL INVIO: "{formData.title}"</p>

      <p>
        4. INVIA IL DOCUMENTO
        <br />
        UNA BOZZA DEL INVIIO SARA CREATO PER LA TUA REVISIONE
      </p>

      <Button className="next-button" type="primary" onClick={onNext}>
        crea una bozza del invio
      </Button>
    </div>
  );
};

export default Third;
