import { apiClient } from 'utils';

import { setAuthData, resetAuthData, setUser } from 'store/user';

import { PromiseThunk } from 'store';
import { IUser } from 'types';

export const checkIsUserAuth = (): PromiseThunk => async (dispatch) => {
  const { accessToken, user: currentUser } = await apiClient.reAuthenticate();

  dispatch(
    setAuthData({
      isAuth: true,
      token: accessToken,
      userObj: currentUser,
    })
  );
};

export const logIn = (values: {
  email: string;
  password: string;
}): PromiseThunk => async (dispatch) => {
  const { accessToken, user: currentUser } = await apiClient.authenticate({
    email: values.email,
    password: values.password,
    strategy: 'local',
  });

  dispatch(
    setAuthData({
      isAuth: true,
      token: accessToken,
      userObj: currentUser,
    })
  );
};

export const logOut = (): PromiseThunk<any> => async (dispatch) => {
  await apiClient.logout();
  dispatch(resetAuthData());
};

export const patchUser = (
  values: Partial<IUser>
): PromiseThunk<IUser> => async (dispatch, getState) => {
  const userId = getState().user.userObj.id;

  const response: IUser = await apiClient
    .service('users')
    .patch(userId, values);
  dispatch(setUser(response));

  return response;
};
