import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroup, IUpload } from 'types';

export interface StoreCore {
  currentStep: number;
  formData: {
    title: string;
    description: string;
    groupsIds: number[] | null;
    condominiumId: number;
    uploadId: number;
    upload: IUpload;
  };
  groups: IGroup[];
}

const initialState: StoreCore = {
  currentStep: 0,
  formData: {
    title: '',
    description: '',
    groupsIds: null,
    condominiumId: null,
    uploadId: null,
    upload: null,
  },
  groups: [],
};

const userSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setCurrentStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStep = payload;
    },

    setFormData: (
      state,
      { payload }: PayloadAction<Partial<StoreCore['formData']>>
    ) => {
      state.formData = {
        ...state.formData,
        ...payload,
      };
    },

    resetFormData: (state) => {
      state.formData = initialState.formData;
    },

    setGroups: (state, { payload }: PayloadAction<StoreCore['groups']>) => {
      state.groups = payload;
    },
  },
});

export const {
  setCurrentStep,
  setFormData,
  resetFormData,
  setGroups,
} = userSlice.actions;

export default userSlice.reducer;
