import { Button, Form, Switch } from 'antd';
import React, { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';

import { setCurrentStep, setFormData } from 'store/core';

const Second = () => {
  const dispatch = useTypedDispatch();

  const formData = useTypedSelector((state) => state.core.formData);
  const groups = useTypedSelector((state) => state.core.groups);

  const [switches, setSwitches] = useState<number[] | null>([]);

  const onNext = async () => {
    dispatch(setFormData({ groupsIds: switches }));
    dispatch(setCurrentStep(2));
  };

  const onSwitchChange = (checked: boolean, num: number) => {
    const oldSwitches = switches || [];
    if (checked) {
      setSwitches(oldSwitches.concat(num));
    } else {
      const index = oldSwitches.indexOf(num);
      const switchesClone = oldSwitches.slice();
      switchesClone.splice(index, 1);
      setSwitches(switchesClone);
    }
  };

  return (
    <div>
      <p>TITOLO DEL INVIO: "{formData.title}"</p>
      <p>2. A CHI DEVE ANDARE LA COMUNICAZIONE</p>
      <Form onFinish={onNext} layout="vertical">
        <div className="radio-group">
          <div className="radio-group__item">
            <span>TUTTI CONDOMINI</span>
            <Switch
              checked={switches === null}
              onChange={(checked) => setSwitches(checked ? null : [])}
            />
          </div>
          {groups.map((group) => (
            <div key={group.id} className="radio-group__item">
              <span>{group.name}</span>
              <Switch
                checked={switches?.includes(group.id)}
                onChange={(checked) => onSwitchChange(checked, group.id)}
              />
            </div>
          ))}
        </div>

        <Button
          disabled={Array.isArray(switches) && switches.length === 0}
          type="primary"
          htmlType="submit"
          className="next-button"
        >
          conferma i destinatari
          <p>e avanza a confermare il documento e firmato</p>
        </Button>
      </Form>
    </div>
  );
};

export default Second;
