import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Switch, Alert } from 'antd';
import { useTypedDispatch, useTypedSelector } from 'store';

import { apiClient } from 'utils';

import { setCurrentStep } from 'store/core';

import { FormInstance } from 'antd/lib/form';
import { IRequestsCount } from 'types';
import UploadDocument from 'components/UploadDocument';

const Fifth = () => {
  const dispatch = useTypedDispatch();

  const formData = useTypedSelector((state) => state.core.formData);
  const groups = useTypedSelector((state) => state.core.groups);

  const [requestsCount, setRequestsCount] = useState<Partial<IRequestsCount>>(
    {}
  );
  const [switches, setSwitches] = useState<number[] | null>([]);
  const [mode, setMode] = useState<'view' | 'security' | 'sent' | 'result'>(
    'view'
  );

  const onNext = () => {
    dispatch(setCurrentStep(5));
  };

  const onFormSubmit = async (values: any) => {
    const data = {
      title: values.title,
      descirption: values.description,
      groupsIds: switches || groups.map((group) => group.id),
      condominiumId: 1,
      uploadId: values.upload.id,
    };

    try {
      const result = await apiClient.service('documents').create(data);
      setRequestsCount(result.requestsCount);
      setMode('sent');
    } catch (e) {
      console.error('err while submitting document', e);
    }
  };

  const onSwitchChange = (checked: boolean, num: number) => {
    const oldSwitches = switches || [];
    if (checked) {
      setSwitches(oldSwitches.concat(num));
    } else {
      const index = oldSwitches.indexOf(num);
      const switchesClone = oldSwitches.slice();
      switchesClone.splice(index, 1);
      setSwitches(switchesClone);
    }
  };

  const isSubmitDisabled = (instance: FormInstance) =>
    !instance.getFieldValue('title') ||
    !instance.getFieldValue('description') ||
    !instance.getFieldValue('upload')?.id ||
    (Array.isArray(switches) && switches.length === 0) ||
    !!instance.getFieldsError().filter(({ errors }) => errors.length).length;

  useEffect(() => {
    setSwitches(formData.groupsIds);
  }, [formData]);

  return (
    <div>
      {['view', 'security'].includes(mode) && <h3>BOZZA DEL INVIIO</h3>}
      {mode === 'sent' && <h3>CONFERMA INVIIO</h3>}
      {mode === 'result' && <h3>TUTTE LE NOTIFICHE MANDATE</h3>}

      {mode !== 'result' ? (
        <Form
          layout="vertical"
          initialValues={formData}
          onFinish={onFormSubmit}
        >
          <div style={{ display: mode === 'view' ? 'block' : 'none' }}>
            <Form.Item
              label="TITOLO DEL INVIO"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Si prega di inserire il titolo!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="TESTO DEL MESSAGIO CON L'INVIO:"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Si prega di inserire un messaggio!',
                },
              ]}
            >
              <Input.TextArea placeholder="Cari condomini. Vi mandiamo la convocazione della prossima assemblea..." />
            </Form.Item>
          </div>

          <Form.Item
            label="DOCUMENTI DA INVIARE"
            name="upload"
            rules={[{ required: true, message: 'Seleziona un documento!' }]}
          >
            <UploadDocument type="edit" />
          </Form.Item>

          <p>DESTINATARI</p>
          <div className="radio-group">
            <div className="radio-group__item">
              <span>TUTTI CONDOMINI</span>
              <Switch
                checked={switches === null}
                onChange={(checked) => setSwitches(checked ? null : [])}
              />
            </div>
            {groups.map((group) => (
              <div key={group.id} className="radio-group__item">
                <span>{group.name}</span>
                <Switch
                  checked={switches?.includes(group.id)}
                  onChange={(checked) => onSwitchChange(checked, group.id)}
                />
              </div>
            ))}
          </div>

          {mode === 'security' && (
            <Alert
              message=""
              description="SEI SICURA/O CHE VUOI INVIARE I DOCUMENTI A QUESTI DESTINATARI?"
              type="warning"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          )}

          {mode === 'sent' && (
            <Alert
              message=""
              description={
                <div className="success-alert">
                  <p>
                    L'INVIO DI QUESTI DOCUMENTI E STATO INVITO A QUESTI
                    DESTINATARI NOTIFICHE
                  </p>
                  <p>- PEC REGISTRATE</p>
                  <p>- EMAIL REGISTRATE</p>
                  <p>- SMS NOTIFICHE RICHIESTE</p>
                </div>
              }
              type="success"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          )}

          {mode === 'view' && (
            <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
              {(instance: FormInstance) => (
                <Button
                  type="primary"
                  disabled={isSubmitDisabled(instance)}
                  onClick={() => setMode('security')}
                >
                  manda l'invio
                </Button>
              )}
            </Form.Item>
          )}
          {mode === 'security' && (
            <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
              {(instance: FormInstance) => (
                <Button
                  type="primary"
                  disabled={isSubmitDisabled(instance)}
                  htmlType="submit"
                >
                  si - manda l'invio
                </Button>
              )}
            </Form.Item>
          )}
        </Form>
      ) : (
        <div className="result">
          <div className="result__item">
            <div className="result__cell">PEC</div>
            <div className="result__cell result__value">
              {requestsCount.pec} PEC MANDATE
            </div>
          </div>
          <div className="result__item">
            <div className="result__cell">EMAIL</div>
            <div className="result__cell result__value">
              {requestsCount.email} EMAIL MANDATE
            </div>
          </div>
          <div className="result__item">
            <div className="result__cell">SMS</div>
            <div className="result__cell result__value">
              {requestsCount.sms} SMS MANDATE
            </div>
          </div>
        </div>
      )}

      <div className="whole-form__actions">
        {['sent', 'result'].includes(mode) && (
          <Button type="primary" onClick={onNext}>
            vedi tutti gli invii
          </Button>
        )}
        {mode === 'sent' && (
          <Button onClick={() => setMode('result')}>visiona notifiche</Button>
        )}
      </div>
    </div>
  );
};

export default Fifth;
